<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.articles") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy
                        v-if="isArticlesLoaded"
                        transition="fade-transition"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy
                        v-if="isArticlesLoaded"
                        transition="fade-transition"
                    >
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="prepareArticle"
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!articles.length">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        :headers="headers"
                        :items="articles"
                        :search="search"
                        :items-per-page="30"
                        :footer-props="{
                            itemsPerPageOptions: [30, 60, 90, -1]
                        }"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editArticle(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:item.category_id="{ item }">
                            <span>{{
                                getCategoryNameById(item.category_id)
                            }}</span>
                        </template>
                        <template v-slot:item.is_visible="{ item }">
                            <span
                                :class="
                                    item.is_visible
                                        ? 'green--text'
                                        : 'red--text'
                                "
                                >{{
                                    item.is_visible
                                        ? $t("general.yes")
                                        : $t("general.no")
                                }}</span
                            >
                        </template>
                    </v-data-table>
                </v-card>

                <ArticleForm
                    :article="selectedArticle"
                    :categories="categories"
                    :visible="dialog.articleForm"
                    :isEdited="isArticleEdited"
                    @addArticle="addArticle"
                    @updateArticle="updateArticle"
                    @close="dialog.articleForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedArticle.title"
                    :text="'articles.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteArticle"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import ArticleForm from "../components/ArticleForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            articles: [],
            categories: [],
            isArticlesLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                articleForm: false
            },
            article: {
                title: null,
                content: null,
                category_id: null,
                meta_description: null,
                is_visible: false,
                created_date: null,
                created_time: null
            },
            selectedArticle: {
                title: null,
                content: null,
                category_id: null,
                meta_description: null,
                is_visible: false,
                created_date: null,
                created_time: null
            },
            defaultArticle: {
                title: null,
                content: null,
                category_id: null,
                meta_description: null,
                is_visible: false,
                created_date: null,
                created_time: null
            },
            defaultSelectedCategory: {
                alias: "-",
                name: "-",
                id: "0"
            },
            isArticleEdited: false
        };
    },

    components: {
        ArticleForm,
        ConfirmationDialog,
        SnackMessage
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false
                },
                {
                    text: this.$t("articles.title"),
                    align: "left",
                    sortable: false,
                    value: "title"
                },
                {
                    text: this.$t("articles.category_id"),
                    align: "left",
                    sortable: false,
                    value: "category_id"
                },
                {
                    text: this.$t("articles.meta_description"),
                    align: "left",
                    sortable: false,
                    value: "meta_description"
                },
                {
                    text: this.$t("general.is_visible"),
                    align: "center",
                    sortable: false,
                    value: "is_visible"
                },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at"
                }
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        }
    },

    created() {
        this.getArticles();
        this.getCategories();
    },

    methods: {
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },
        getCategoryNameById(id) {
            let category = this.categories.filter(obj => {
                return obj.id === id;
            });

            return category[0] ? category[0].name : "-";
        },
        async getArticles() {
            await axios
                .get("/api/articleList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.articles = response.data;
                    this.isArticlesLoaded = true;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        },
        async getCategories() {
            await axios
                .get("/api/categoryList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.categories = response.data;
                    this.categories.unshift(this.defaultSelectedCategory);
                    this.isCategoriesLoaded = true;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        },
        prepareArticle() {
            this.selectedArticle = Object.assign({}, this.defaultArticle);

            this.dialog.articleForm = true;
            this.isArticleEdited = false;
        },
        addArticle(article) {
            this.articles.push(article);
        },
        editArticle(item) {
            this.selectedArticle = Object.assign({}, item);
            this.dialog.articleForm = true;
            this.isArticleEdited = true;
        },
        updateArticle(article) {
            let index = this.articles.findIndex(item => item.id == article.id);
            this.articles.splice(index, 1, article);
        },
        confirmDelete(item) {
            this.selectedArticle = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteArticle() {
            this.dialog.confirmation = false;
            let index = this.articles.indexOf(this.selectedArticle);

            await axios
                .delete(`/api/deleteArticle/${this.selectedArticle.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.selectedArticle = this.defaultArticle;
                    this.articles.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "articles.deleted_successfully",
                        "success"
                    );
                })
                .catch(error => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        }
    }
};
</script>
