<template>
    <v-dialog v-model="show" max-width="1200px" :retain-focus="false">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("articles.edit_article")
                }}</span>
                <span class="headline" v-else>{{
                    $t("articles.add_article")
                }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="article.title"
                                    :label="$t('articles.title')"
                                    autofocus
                                    :rules="rules.field"
                                ></v-text-field
                            ></v-col>
                            <v-col cols="12" sm="6">
                                <v-select
                                    :items="categories"
                                    v-model="article.category_id"
                                    item-value="id"
                                    item-text="name"
                                    :label="$t('articles.category_id')"
                                    :rules="rules.select"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <label>{{ $t("articles.content") }}</label>
                                <editor
                                    :editorData="article.content"
                                    @insertEditorData="insertEditorData"
                                ></editor>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-model="article.meta_description"
                                    :label="$t('articles.meta_description')"
                                    rows="3"
                                    maxlength="150"
                                    counter
                                    class="mt-5"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-menu
                                    v-model="menuDate"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFormatted"
                                            :label="$t('events.date')"
                                            append-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="rules.field"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="article.created_date"
                                        @change="menuDate = false"
                                        locale="cs"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menuTime"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="article.created_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="article.created_time"
                                            :label="$t('events.time')"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="rules.field"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="menuTime"
                                        v-model="article.created_time"
                                        full-width
                                        @click:minute="
                                            $refs.menu.save(
                                                article.created_time
                                            )
                                        "
                                        format="24hr"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-checkbox
                            v-model="article.is_visible"
                            hide-details
                            class="shrink mr-2 mt-0"
                            :label="$t('articles.is_visible')"
                        ></v-checkbox>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="submit">{{
                    $t("general.save")
                }}</v-btn>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import Editor from "../components/CkeditorForm";

export default {
    name: "ArticleForm",
    props: {
        form: {
            type: Object
        },
        article: Object,
        categories: Array,
        visible: Boolean,
        isEdited: Boolean
    },

    components: {
        Editor
    },

    data() {
        return {
            input: {
                empty: "-"
            },
            menuTime: false,
            menuDate: false,
            dateFormatted: "",
            valid: true,
            is_visible: false,
            rules: {
                field: [value => !!value || this.$t("general.required")]
                // select: [v => !!v || this.$t("general.required")]
            }
        };
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            }
        }
    },

    watch: {
        article: {
            handler: function(val, oldVal) {
                this.dateFormatted = this.formatDate(this.article.created_date);
            },
            deep: true
        }
    },

    methods: {
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        insertEditorData(ckeditorContent) {
            this.article.content = ckeditorContent;
        },
        async submit() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    await axios
                        .put(
                            `/api/updateArticle/${this.article.id}`,
                            this.article,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        this.$store.state.tokenData.user
                                            .access_token
                                }
                            }
                        )
                        .then(response => {
                            this.$emit("updateArticle", response.data);
                            this.$emit(
                                "snackMessage",
                                "articles.article_saved",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storeArticle`, this.article, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            this.$emit("addArticle", response.data);
                            this.$emit(
                                "snackMessage",
                                "articles.article_added",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        }
    }
};
</script>
