<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.newsLetter") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy
                        v-if="isNewsLettersLoaded"
                        transition="fade-transition"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy
                        v-if="isNewsLettersLoaded"
                        transition="fade-transition"
                    >
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="
                                prepareNewsLetter
                            "
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!newsLetters.length">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        :headers="headers"
                        :items="newsLetters"
                        :search="search"
                        :items-per-page="30"
                        :footer-props="{
                            itemsPerPageOptions: [30, 60, 90, -1]
                        }"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editNewsLetter(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-data-table>
                </v-card>

                <NewsLetterForm
                    :newsLetter="selectedNewsLetter"
                    :visible="dialog.newsLetterForm"
                    :isEdited="isNewsLetterEdited"
                    @addNewsLetter="addNewsLetter"
                    @updateNewsLetter="updateNewsLetter"
                    @close="dialog.newsLetterForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedNewsLetter.title"
                    :text="'newsLetters.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteNewsLetter"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>

import NewsLetterForm from "../components/NewsLetterForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            newsLetters: [],
            isNewsLettersLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                newsLetterForm: false
            },
            newsLetter: {
                email: null,
            },
            selectedNewsLetter: {
                email: null,
            },
            defaultNewsLetter: {
                email: null,
            },
            isNewsLetterEdited: false
        };
    },

    components: {
        NewsLetterForm,
        ConfirmationDialog,
        SnackMessage
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false
                },
                {
                    text: this.$t("newsLetters.email"),
                    align: "left",
                    sortable: false,
                    value: "email"
                },
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        }
    },

    created() {
        this.getNewsLetters();
    },

    methods: {
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },
        async getNewsLetters() {
            await axios
                .get("/api/newsLetterList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.newsLetters = response.data;
                    this.isNewsLettersLoaded = true;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        },
        prepareNewsLetter() {
            this.selectedNewsLetter = Object.assign({}, this.defaultNewsLetter);
            
            this.dialog.newsLetterForm = true;
            this.isNewsLetterEdited = false;
        },
        addNewsLetter(newsLetter) {
            this.newsLetters.push(newsLetter);
        },
        editNewsLetter(item) {
            this.selectedNewsLetter = Object.assign({}, item);
            this.dialog.newsLetterForm = true;
            this.isNewsLetterEdited = true;
        },
        updateNewsLetter(newsLetter) {
            let index = this.newsLetters.findIndex(item => item.id == newsLetter.id);
            this.newsLetters.splice(index, 1, newsLetter);
        },
        confirmDelete(item) {
            this.selectedNewsLetter = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteNewsLetter() {
            this.dialog.confirmation = false;
            let index = this.newsLetters.indexOf(this.selectedNewsLetter);

            await axios
                .delete(`/api/deleteNewsLetter/${this.selectedNewsLetter.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.selectedNewsLetter = this.defaultNewsLetter;
                    this.newsLetters.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "newsLetters.deleted_successfully",
                        "success"
                    );
                })
                .catch(error => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        }
    }
};
</script>
