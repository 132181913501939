<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.messages") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy
                        v-if="isMessagesLoaded"
                        transition="fade-transition"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy
                        v-if="isMessagesLoaded"
                        transition="fade-transition"
                    >
                        <ExportExcelButton></ExportExcelButton>
                    </v-lazy>
                    <v-lazy
                        v-if="isMessagesLoaded"
                        transition="fade-transition"
                    >
                        <v-btn
                            color="warning"
                            dark
                            small
                            @click="confirmOpenDialogHideMessages()"
                            >{{ $t("messages.hide_in_backend") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!messages.length">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-card-title></v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="messages"
                        :search="search"
                        :items-per-page="30"
                        :footer-props="{
                            itemsPerPageOptions: [30, 60, 90, -1]
                        }"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'cyan'"
                                            size="20px"
                                            color="cyan"
                                            class="mr-2"
                                            @click="showMessage(item)"
                                            v-on="on"
                                            >mdi-eye-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.show") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-data-table>
                </v-card>

                <MessageDialog
                    :message="selectedMessage"
                    :visible="dialog.messageDialog"
                    @close="dialog.messageDialog = false"
                />

                <ConfirmationDialogHideMessages
                    v-if="dialog.confirmation"
                    :text="'messages.hide_confirmation'"
                    @confirm="hideActiveMessages"
                    @cancel="confirmCloseDialogHideMessages"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import MessageDialog from "../components/MessageDialog";
import ConfirmationDialogHideMessages from "../components/ConfirmationDialogHideMessages";
import SnackMessage from "../components/SnackMessage";
import ExportExcelButton from "../components/ExportExcelButton";

export default {
    data() {
        return {
            messages: [],
            isMessagesLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                messageDialog: false
            },
            message: {
                name: null,
                surname: null,
                father_name: null,
                phone: null,
                email: null,
                street: null,
                city: null,
                zip_code: null,
                course: null,
                level: null,
                experince: null,
                text: null,
                is_agreement: false,
                is_checked: null,
                is_active_backend: false
            },
            selectedMessage: {
                name: null,
                surname: null,
                father_name: null,
                phone: null,
                email: null,
                street: null,
                city: null,
                zip_code: null,
                course: null,
                level: null,
                experince: null,
                text: null,
                is_agreement: false,
                is_checked: null,
                is_active_backend: false
            },
            defaultMessage: {
                name: null,
                surname: null,
                father_name: null,
                phone: null,
                email: null,
                street: null,
                city: null,
                zip_code: null,
                course: null,
                level: null,
                experince: null,
                text: null,
                is_agreement: false,
                is_checked: null,
                is_active_backend: false
            }
        };
    },

    components: {
        MessageDialog,
        ConfirmationDialogHideMessages,
        SnackMessage,
        ExportExcelButton
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false
                },
                {
                    text: this.$t("messages.name"),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t("messages.surname"),
                    align: "left",
                    sortable: false,
                    value: "surname"
                },
                {
                    text: this.$t("messages.phone"),
                    align: "left",
                    sortable: false,
                    value: "phone"
                },
                {
                    text: this.$t("messages.email"),
                    align: "left",
                    sortable: false,
                    value: "email"
                },
                {
                    text: this.$t("messages.text"),
                    align: "left",
                    sortable: false,
                    value: "text"
                },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at"
                }
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        }
    },

    created() {
        this.getMessages();
    },

    methods: {
        confirmOpenDialogHideMessages() {
            this.dialog.confirmation = true;
        },
        confirmCloseDialogHideMessages() {
            this.dialog.confirmation = false;
        },
        async getMessages() {
            await axios
                .get("/api/messageList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.messages = response.data;
                    this.isMessagesLoaded = true;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        },
        showMessage(item) {
            this.selectedMessage = Object.assign({}, item);
            this.dialog.messageDialog = true;
        },
        async hideActiveMessages() {
            this.dialog.confirmation = false;

            await axios
                .get(`/api/hideMessages`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.messages = response.data;
                    this.isMessagesLoaded = true;

                    this.$refs.SnackMessage.showMessage(
                        "messages.hidden_successfully",
                        "success"
                    );
                })
                .catch(error => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        }
    }
};
</script>
