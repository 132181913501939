<template>
    <file-manager
        v-bind:settings="settings"
        class="itb-component-hight"
    ></file-manager>
</template>

<script>
import Vue from "vue";
import FileManager from "laravel-file-manager";
import store from "../store.js";

Vue.use(FileManager, { store });

export default {
    data() {
        return {
            storePath: "",
            settings: {
                // axios headers
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    Authorization: `Bearer ${window.localStorage.getItem(
                        "user-token"
                    )}`
                },
                baseUrl: "../file-manager/", // overwrite base url Axios
                windowsConfig: 2, // overwrite config
                lang: "cs",
                translation: {
                    // name: en,
                    content: {
                        about: "Über",
                        back: "Zurück"
                    }
                }
            }
        };
    },
    created() {
        this.$store.state.fm.left.viewType = "grid";
    }
};
</script>

<style>
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.btn-group:nth-last-child(1) {
    display: none;
}
.justify-content-between.fm-info-block {
    display: none;
}
.fm .fm-body {
    border-bottom: none;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    min-height: 600px;
}
.fm-tree {
    overflow: auto;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.table > :not(:last-child) > :last-child > * {
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
}
.btn-secondary {
    color: #fff;
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
}
.fm-breadcrumb .breadcrumb.active-manager {
    background: linear-gradient(to bottom right, #2196f3, #00b0ff) !important;
}
</style>
