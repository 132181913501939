<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline" v-if="isEdited">{{ $t("categories.edit_category") }}</span>
        <span class="headline" v-else>{{ $t("categories.add_category") }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="category.name"
                  :label="$t('categories.name')"
                  autofocus
                  :rules="rules"
                ></v-text-field>
                <v-select
                  :items="categories"
                  v-model="category.parent_category_id"
                  item-value="id"
                  item-text="name"
                  :label="$t('categories.parent_category_id')"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="submit">{{ $t("general.save") }}</v-btn>
        <v-btn @click.stop="show=false">{{ $t("general.cancel") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: "CategoryForm",
  props: {
    form: {
      type: Object,
    },
    category: Object,
    categories: Array,
    visible: Boolean,
    isEdited: Boolean,
  },

  data() {
    return {
      valid: true,
      rules: [(value) => !!value || this.$t("general.required")],
    };
  },

  watch: {
    show(val) {
      !val && this.$refs.form.reset();
    },
  },

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.isEdited) {
          await axios
            .put(`/api/updateCategory/${this.category.id}`, this.category, {
              headers: {
                Authorization:
                  "Bearer " + this.$store.state.tokenData.user.access_token,
              },
            })
            .then((response) => {
              this.$emit("updateCategory", response.data);
              this.$emit("snackMessage", "categories.category_saved", "success");
              this.$emit("close");
            })
            .catch((error) => {
              console.log(error);
              this.$emit("snackMessage", "general.error", "error");
            });
        } else {
          await axios
            .post(`/api/storeCategory`, this.category, {
              headers: {
                Authorization:
                  "Bearer " + this.$store.state.tokenData.user.access_token,
              },
            })
            .then((response) => {
              this.$emit("addCategory", response.data);
              this.$emit("snackMessage", "categories.category_added", "success");
              this.$emit("close");
            })
            .catch((error) => {
              console.log(error);
              this.$emit("snackMessage", "general.error", "error");
            });
        }
      }
    },
  },
};
</script>
