var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app-content"}},[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.primaryDrawer.mini,"app":"","clipped":""}},[_c('v-list',[_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'categories' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-clipboard-list-outline")])],1)])]}}],null,false,3277430639)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.categories")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-clipboard-list-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.categories")))])],1)],1)],1),_vm._v(" "),_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'articles' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1)])]}}],null,false,4195597928)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.articles")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.articles")))])],1)],1)],1),_vm._v(" "),_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'posts' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-post-outline")])],1)])]}}],null,false,3292325780)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.posts")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-post-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.posts")))])],1)],1)],1),_vm._v(" "),_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'calendar' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-calendar")])],1)])]}}],null,false,2299518899)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.calendar")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-calendar")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.calendar")))])],1)],1)],1),_vm._v(" "),_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'newsLetters' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-email-newsletter")])],1)])]}}],null,false,13618353)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.newsLetter")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-email-newsletter")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.newsLetter")))])],1)],1)],1),_vm._v(" "),_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'galleries' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-folder-multiple-image")])],1)])]}}],null,false,521768160)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.galleries")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-folder-multiple-image")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.galleries")))])],1)],1)],1),_vm._v(" "),_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'messages' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-email-outline")])],1)])]}}],null,false,1577041056)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.messages")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-email-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.messages")))])],1)],1)],1),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('label',{staticClass:"py-2 pl-4 d-block itb-drawer-label mt-3"},[(_vm.primaryDrawer.mini)?_c('v-icon',[_vm._v("mdi-cog-outline")]):_c('span',[_vm._v(_vm._s(_vm.$t("title.settings")))])],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'users' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-account-multiple")])],1)])]}}],null,false,688950813)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.users")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.users")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'settings' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-order-bool-ascending-variant")])],1)])]}}],null,false,3026541733)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.settings_general")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-order-bool-ascending-variant")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.settings_general")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'logos' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-tooltip-image")])],1)])]}}],null,false,33592414)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.logos")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-tooltip-image")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.logos")))])],1)],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-app-bar',{attrs:{"app":"","clipped-left":"","dark":"","color":"primary"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.primaryDrawer.mini = !_vm.primaryDrawer.mini}}}),_vm._v(" "),_c('v-toolbar-title',[_c('img',{attrs:{"align":"middle","src":"/images/logo-admin.png","height":"50"}})]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 itb-icon-tooltip",attrs:{"color":"yellow"}},'v-icon',attrs,false),on),[_vm._v("mdi-crown-outline")])]}}],null,false,2400497158)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.wau_you_are_admin")))])]):_vm._e(),_vm._v("\n        "+_vm._s(_vm.$store.state.profile.email)+"\n        "),_c('v-spacer'),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-decoration-none",attrs:{"to":"/","target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-laptop")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.frontend")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"deep-orange accent-3"},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout-variant")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.sign_out")))])])],1),_vm._v(" "),_c('v-main',[_c('router-view')],1),_vm._v(" "),_c('v-footer',{attrs:{"app":"","color":"white"}},[_c('span',[_vm._v("\n            © 2020 2022\n            "),_c('a',{attrs:{"href":"https://itband.eu","target":"_blank"}},[_vm._v("it_BAND")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }