import Vue from "vue";
import Vuex from "vuex";
import Jwt from "jsonwebtoken";

Vue.use(Vuex);

const getDefaultState = () => {
    let token = localStorage.getItem("itb-spa-token");
    let tokenData = token ? Jwt.decode(token) : null;

    return {
        isLoggedIn: false,
        tokenData: tokenData,
        profile: token ? tokenData.user.user : {}
    };
};

const store = new Vuex.Store({
    state: getDefaultState(),
    mutations: {
        authenticate(state, payload) {
            state.isLoggedIn = localStorage.getItem("itb-spa-token")
                ? true
                : false;

            if (state.isLoggedIn) {
                state.profile = payload;
                let token = localStorage.getItem("itb-spa-token");
                state.tokenData = token ? Jwt.decode(token) : null;
            } else {
                state.profile = {};
            }
        },
        resetState(state) {
            state.isLoggedIn = false;
            state.tokenData = null;
            state.profile = {};
        }
    },
    actions: {
        authenticate(context, payload) {
            context.commit("authenticate", payload);
        },
        resetState(context) {
            context.commit("resetState");
        }
    }
});

export default store;
