<template>
    <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("newsLetters.edit_newsLetter")
                }}</span>
                <span class="headline" v-else>{{
                    $t("newsLetters.add_newsLetter")
                }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    ref="email"
                                    v-model="newsLetter.email"
                                    :label="$t('newsLetters.email')"
                                    autofocus
                                    :rules="emailRules"
                                    required
                                ></v-text-field
                            ></v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="submit">{{
                        $t("general.save")
                    }}</v-btn>
                    <v-btn @click.stop="show = false">{{
                        $t("general.cancel")
                    }}</v-btn>
                </v-card-actions>
           
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "NewsLetterForm",
    props: {
        form: {
            type: Object
        },
        newsLetter: Object,
        visible: Boolean,
        isEdited: Boolean,
    },

    data(){
        return {

            valid: true,
            is_visible: false,
            emailRules: [
                v => !!v || 'E-mail je vyžadován',
                v => /.+@.+\..+/.test(v) || 'E-mail musí být platný',
            ],
        }
    },

    watch: {
        show(val) {
            !val && this.$refs.form.reset();
        }
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            }
        }
    },

    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    await axios
                        .put(
                            `/api/updateNewsLetter/${this.newsLetter.id}`,
                            this.newsLetter,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        this.$store.state.tokenData.user
                                            .access_token
                                }
                            }
                        )
                        .then(response => {
                            this.$emit("updateNewsLetter", response.data);
                            this.$emit(
                                "snackMessage",
                                "newsLetters.newsLetter_saved",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/createNewsLetter`, this.newsLetter, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            if (response.data.isError) {
                                this.$refs.email.focus();
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                            } else {
                                this.$emit("addNewsLetter", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "newsLetters.newsLetter_added",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        },
    }
};
</script>
