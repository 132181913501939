<template>
    <div id="appCkeditor">
        <ckeditor
            :editor="editor"
            v-model="editorDataContent"
            :config="editorConfig"
            @ready="prefill"
        ></ckeditor>

        <div class="text-center">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                ref="Ckeditor"
            >
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            <span class="headline">{{
                                $t("title.galleries")
                            }}</span>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn
                            depressed
                            color="primary"
                            class="mr-2"
                            @click="openAltDialog()"
                        >
                            <v-icon dark left> mdi-import </v-icon>
                            {{ $t("general.insert") }}
                        </v-btn>
                    </v-toolbar>
                    <SnackMessage ref="SnackMessage" />
                    <manager> </manager>

                    <v-divider></v-divider>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlockPlugin from "@ckeditor/ckeditor5-indent/src/indentblock";
import FontPlugin from "@ckeditor/ckeditor5-font/src/font";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";
import ListStylePlugin from "@ckeditor/ckeditor5-list/src/liststyle";
import TodoListPlugin from "@ckeditor/ckeditor5-list/src/todolist";
import TablePlugin from "@ckeditor/ckeditor5-table/src/table";
import TableToolbarPlugin from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TablePropertiesPlugin from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellPropertiesPlugin from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
import RemoveFormatPlugin from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import HtmlEmbedPlugin from "@ckeditor/ckeditor5-html-embed/src/htmlembed";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import ClipboardPlugin from "@ckeditor/ckeditor5-clipboard/src/clipboard";

import InsertImage from "../../insertImage.js";

import Manager from "../components/FileManagerForm";
import SnackMessage from "../components/SnackMessage";
import store from "../store.js";

export default {
    name: "appCkeditor",
    props: {
        editorData: {
            type: String
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            // editorData: "<p>Content of the editor.</p>",
            editorDataContent: "",
            dialog: false,
            editorConfig: {
                plugins: [
                    EssentialsPlugin,
                    BoldPlugin,
                    ItalicPlugin,
                    LinkPlugin,
                    ParagraphPlugin,
                    HeadingPlugin,
                    UnderlinePlugin,
                    StrikethroughPlugin,
                    IndentPlugin,
                    IndentBlockPlugin,
                    FontPlugin,
                    AlignmentPlugin,
                    ListStylePlugin,
                    TablePlugin,
                    TableToolbarPlugin,
                    ImagePlugin,
                    ImageToolbarPlugin,
                    ImageStylePlugin,
                    ImageResizePlugin,
                    TablePropertiesPlugin,
                    TableCellPropertiesPlugin,
                    RemoveFormatPlugin,
                    TodoListPlugin,
                    HtmlEmbedPlugin,
                    SubscriptPlugin,
                    SuperscriptPlugin,
                    ClipboardPlugin
                ],
                toolbar: {
                    items: [
                        "undo",
                        "redo",
                        "|",
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "subscript",
                        "superscript",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        // "fontSize",
                        // "fontFamily",
                        "fontColor",
                        "fontBackgroundColor",
                        "|",
                        "alignment",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "todoList",
                        "|",
                        "link",
                        "insertImagePlugin",
                        "htmlEmbed",
                        "|",
                        "insertTable",
                        "|",
                        "removeFormat"
                    ],
                    shouldNotGroupWhenFull: true
                },
                heading: {
                    options: [
                        {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "ck-heading_paragraph"
                        },
                        {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2"
                        },
                        {
                            model: "heading3",
                            view: "h3",
                            title: "Heading 3",
                            class: "ck-heading_heading3"
                        }
                    ]
                },
                htmlEmbed: {
                    showPreviews: true
                    // sanitizeHtml: inputHtml => {
                    //     // Strip unsafe elements and attributes, e.g.:
                    //     // the `<script>` elements and `on*` attributes.
                    //     const outputHtml = sanitize(inputHtml);

                    //     return {
                    //         html: outputHtml,
                    //         // true or false depending on whether the sanitizer stripped anything.
                    //         hasChanged: true
                    //     };
                    // }
                },
                table: {
                    contentToolbar: [
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        "tableProperties",
                        "tableCellProperties"
                    ]
                },
                extraPlugins: [InsertImage],
                image: {
                    styles: [
                        "full",
                        "side",
                        "alignLeft",
                        "alignCenter",
                        "alignRight"
                    ],
                    toolbar: [
                        "imageTextAlternative",
                        "|",
                        "imageStyle:full",
                        "|",
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                        "|"
                    ]
                },
                licenseKey: ""
                // The configuration of the editor.
            }
        };
    },
    components: {
        Manager,
        SnackMessage
    },
    created() {
        window.addEventListener("MyEventType", this.imageDialog);
    },
    destroyed: function() {
        window.removeEventListener("MyEventType", this.imageDialog);
    },
    watch: {
        editorData: function(val) {
            if (val == null) {
                this.editorDataContent = "";
            } else {
                this.editorDataContent = this.editorData;
            }
        },
        editorDataContent(newVal) {
            this.$emit("insertEditorData", newVal);
        }
    },
    methods: {
        prefill(editor) {
            if (this.editorData == null) {
                this.editorDataContent = "";
            } else {
                this.editorDataContent = this.editorData;
            }
        },
        imageDialog() {
            this.dialog = true;
        },
        insertImage() {
            var evt = new CustomEvent("InsertEvent", {
                detail: `/uploads/${this.$store.state.fm.left.selected.files[0]}`
            });
            window.dispatchEvent(evt);
            this.dialog = false;
        },
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },
        openAltDialog() {
            if (this.$store.state.fm.left.selected.files[0] == undefined) {
                this.$refs.SnackMessage.showMessage(
                    "general.pick_picture",
                    "error"
                );
                this.dialog = true;
            } else {
                this.insertImage();
            }
        }
    }
};
</script>

<style scope>
.ck-editor__editable {
    min-height: 200px;
    resize: vertical;
}
</style>
