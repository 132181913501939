require("./bootstrap");
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";

window.Vue = require("vue");

import Vue from "vue";
import Vuetify from "vuetify";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import Jwt from "jsonwebtoken";
import store from "./backend/store";
import CKEditor from "@ckeditor/ckeditor5-vue2";

Vue.use(CKEditor);

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(Vuetify);

Vue.component(
    "app-container",
    require("./backend/views/AppContainer.vue").default
);

import Login from "./backend/views/Login.vue";
import ResetPassword from "./backend/views/ResetPassword.vue";
import Error404 from "./backend/views/Error404.vue";
import AdminContainer from "./backend/views/AdminContainer.vue";
import Dashboard from "./backend/views/Dashboard.vue";
import Articles from "./backend/views/Articles.vue";
import Posts from "./backend/views/Posts.vue";
import Galleries from "./backend/views/Galleries.vue";
import Logos from "./backend/views/Logos.vue";
import Categories from "./backend/views/Categories.vue";
import Messages from "./backend/views/Messages.vue";
import Events from "./backend/views/Events.vue";
import NewsLetters from "./backend/views/NewsLetters.vue";
import Users from "./backend/views/Users.vue";
import Settings from "./backend/views/Settings.vue";

const routes = [
    { path: "/login", name: "login", component: Login },
    {
        path: "/obnovit-heslo",
        name: "reset-password",
        component: ResetPassword
    },
    {
        path: "/admin",
        component: AdminContainer,
        children: [
            { path: "", redirect: "clanky" },
            { path: "clanky", name: "articles", component: Articles },
            { path: "aktuality", name: "posts", component: Posts },
            { path: "galerie", name: "galleries", component: Galleries },
            { path: "kategorie", name: "categories", component: Categories },
            {
                path: "kontaktni-formular",
                name: "messages",
                component: Messages
            },
            { path: "kalendar", name: "calendar", component: Events },
            {
                path: "odber-aktualit",
                name: "newsLetters",
                component: NewsLetters
            },
            { path: "logos", name: "logos", component: Logos },
            {
                path: "uzivatele",
                name: "users",
                component: Users,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                }
            },
            {
                path: "nastaveni",
                name: "settings",
                component: Settings,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                }
            }
        ],
        beforeEnter(to, from, next) {
            const token = localStorage.getItem("itb-spa-token");
            if (token != null) {
                next();
            } else {
                next("/login");
            }
        }
    },
    { path: "*", name: "error404", component: Error404 }
];

const router = new VueRouter({
    mode: "history",
    routes
});

function loadLocalMessages() {
    const locales = require.context(
        "../../src/locales/backend",
        true,
        /[A-za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};

    locales.keys().forEach(key => {
        const matched = key.match(/([A-za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "cs", //Set locale
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en", //Set fallback locale
    messages: loadLocalMessages() //Set locale messages
});

const opts = {
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    }
};

export default new Vuetify(opts);

// Directives
Vue.directive("tr-class-on-hover", {
    bind(el, binding) {
        console.log("tr-class-on-hover binded");
        const { value = "" } = binding;
        el.addEventListener("mouseenter", () => {
            el.closest("tr").classList.add("actions-hover-" + value);
        });
        el.addEventListener("mouseleave", () => {
            el.closest("tr").classList.remove("actions-hover-" + value);
        });
    }
});

const app = new Vue({
    el: "#app",
    vuetify: new Vuetify(),
    i18n,
    router,
    store
});
