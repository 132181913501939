<template>
    <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ $t("messages.show_message") }}</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="message.name"
                                :label="$t('messages.name')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.surname"
                                :label="$t('messages.surname')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.birth"
                                :label="$t('messages.birth')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.insurance"
                                :label="$t('messages.insurance')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.father_name"
                                :label="$t('messages.fatherName')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.phone"
                                :label="$t('messages.phone')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.email"
                                :label="$t('messages.email')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.street"
                                :label="$t('messages.street')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.city"
                                :label="$t('messages.city')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.zip_code"
                                :label="$t('messages.zipCode')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.course"
                                :label="$t('messages.course')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.level"
                                :label="$t('messages.level')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="message.experience"
                                :label="$t('messages.experience')"
                                disabled
                            ></v-text-field>
                            <v-textarea
                                v-model="message.text"
                                :label="$t('messages.text')"
                                disabled
                            ></v-textarea>
                            <v-text-field
                                v-model="reversedMessageAgreement"
                                :label="$t('messages.agreement')"
                                disabled
                            ></v-text-field>
                            <v-text-field
                                v-model="reversedMessageChecked"
                                :label="$t('messages.checked')"
                                disabled
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "MessageDialog",
    props: {
        message: Object,
        visible: Boolean
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            }
        },
        reversedMessageChecked: function() {
            // `this` points to the vm instance
            return this.message.is_checked
                ? this.$t("general.yes")
                : this.$t("general.no");
        },
        reversedMessageAgreement: function() {
            // `this` points to the vm instance
            return this.message.is_agreement
                ? this.$t("general.yes")
                : this.$t("general.no");
        }
    }
};
</script>
