<template>
    <v-row>
        <v-col cols="10" class="py-0">
            <div
                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-file-input"
            >
                <div class="v-input__control">
                    <div class="v-input__slot">
                        <div class="v-text-field__slot">
                            <label
                                class="v-label theme--light v-label--active"
                                style="left: 0px; right: auto; position: absolute"
                                >{{ label }}</label
                            >
                            <div class="v-file-input__text">
                                {{ attachment.name ? attachment.name : "" }}
                            </div>
                            <input
                                ref="fileInput"
                                type="file"
                                @change="setAttachmentName($event, attachment)"
                                :accept="accept"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="2" class="p-0">
            <v-btn
                icon
                color="red"
                class="mt-3"
                @click="removeAttachment(attachment)"
            >
                <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "AttachmentInput",
    props: {
        label: "",
        attachment: "",
        accept: ""
    },

    methods: {
        setAttachmentName(event, attachment) {
            var file = event.target.files[0];

            if (file) {
                attachment.file = file;
                attachment.name = file.name;
            }

            if (attachment.id) {
                this.$emit("removeAttachment", attachment.id);
            }
        },
        removeAttachment(attachment) {
            this.attachment.file = null;
            this.attachment.name = "";

            if (attachment.id) {
                this.$emit("removeAttachment", attachment.id);
            }
        }
    }
};
</script>
