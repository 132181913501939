<template>
    <div class="mr-3">
        <export-excel
            class="btn-modified btn-primary btn-sm "
            :fetch="fetchData"
            :fields="json_fields"
            worksheet="My Messages"
            name="messages.xls"
        >
            EXPORT
        </export-excel>
    </div>
</template>

<script>
import Vue from "vue";
import excel from "vue-excel-export";

Vue.use(excel);

export default {
    data() {
        return {
            messages: [],
            json_fields: {
                Jméno: "name",
                Příjmení: "surname",
                "Rodné číslo": "birth",
                "Zdravotní pojišťovna": "insurance",
                "Jméno a příjmení rodiče (zákonného zástupce)": "father_name",
                Telefon: "phone",
                "E-mail": "email",
                Ulice: "street",
                Město: "city",
                PSČ: "zip_code",
                "Má zájem o výuku": "course",
                "Stávající zdatnost": "level",
                "Zúčastnil/a se přihlášený/á Veřejné lyžařské školy v předešlém roce?":
                    "experience",
                "Zařadit do družstva společně s": "text",
                "Souhlasí s podmínkami": {
                    field: "is_agreement",
                    callback: value => {
                        return `${(value = 1 ? "ano" : "ne")}`;
                    }
                },
                "Potvrzuje že se seznámil se zásadami": {
                    field: "is_checked",
                    callback: value => {
                        return `${(value = 1 ? "ano" : "ne")}`;
                    }
                },
                Vytvořeno: "created_at"
            }
        };
    },

    methods: {
        async fetchData() {
            const response = await axios.get(`/api/export`, {
                headers: {
                    Authorization:
                        "Bearer " +
                        this.$store.state.tokenData.user.access_token
                }
            });
            return response.data;
        }
    }
};
</script>

<style>
.btn-modified {
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
    height: 28px;
    font-size: 0.75rem;
    line-height: 1.8;
    cursor: pointer;
}
</style>
