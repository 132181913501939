<template>
    <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("events.edit_event")
                }}</span>
                <span class="headline" v-else>{{
                    $t("events.add_event")
                }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    v-model="event.title"
                                    :label="$t('events.title')"
                                    autofocus
                                    :rules="rules"
                                ></v-text-field
                            ></v-col>
                        </v-row>

                        <v-row>
                            
                            <v-col
                                cols="12"
                                lg="6"
                            >
                                <v-menu
                                    v-model="menuDate"
                                    :close-on-content-click="false"
                                    max-width="290"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="dateFormatted"
                                        :label="$t('events.date')"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="event.date"
                                        @change="menuDate = false"
                                        locale="cs"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col
                            cols="12"
                            lg="6"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menuTime"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="event.time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="event.time"
                                        :label="$t('events.time')"
                                        append-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    v-if="menuTime"
                                    v-model="event.time"
                                    full-width
                                    @click:minute="$refs.menu.save(event.time)"
                                    format='24hr'
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="submit">{{
                        $t("general.save")
                    }}</v-btn>
                    <v-btn @click.stop="show = false">{{
                        $t("general.cancel")
                    }}</v-btn>
                </v-card-actions>
           
        </v-card>
    </v-dialog>
</template>

<script>
import { format, parseISO } from 'date-fns'

export default {
    name: "EventForm",
    props: {
        form: {
            type: Object
        },
        event: Object,
        visible: Boolean,
        isEdited: Boolean,
    },

    data(){
        return {
            menuTime: false,
            menuDate: false,
            dateFormatted: '',
            valid: true,
            is_visible: false,
            rules: [value => !!value || this.$t("general.required")],
        }
    },

    watch: {
        event: {
            handler: function (val, oldVal) {
                this.dateFormatted = this.formatDate(this.event.date);
            },
            deep: true
        }
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            }
        }
    },

    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
        async submit() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    await axios
                        .put(
                            `/api/updateEvent/${this.event.id}`,
                            this.event,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        this.$store.state.tokenData.user
                                            .access_token
                                }
                            }
                        )
                        .then(response => {
                            this.$emit("updateEvent", response.data);
                            this.$emit(
                                "snackMessage",
                                "events.event_saved",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storeEvent`, this.event, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            this.$emit("addEvent", response.data);
                            this.$emit(
                                "snackMessage",
                                "events.event_added",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        },
    }
};
</script>
