<template>
    <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("galleries.edit_gallery")
                }}</span>
                <span class="headline" v-else>{{
                    $t("galleries.add_gallery")
                }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    v-model="gallery.title"
                                    :label="$t('galleries.title')"
                                    autofocus
                                    :rules="rules"
                                ></v-text-field
                            ></v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-model="gallery.description"
                                    :label="$t('galleries.description')"
                                    :rules="rules"
                                ></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    v-model="gallery.google_drive_url"
                                    :label="$t('galleries.google_drive_url')"
                                    :rules="rulesURL"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12">
                                <AttachmentInput
                                    :label="$t('galleries.image')"
                                    :attachment="gallery.image"
                                    accept="image/*"
                                    @removeAttachment="removeAttachment"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-menu
                                    v-model="menuDate"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFormatted"
                                            :label="$t('galleries.date')"
                                            append-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="gallery.created_date"
                                        @change="menuDate = false"
                                        locale="cs"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menuTime"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="gallery.created_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="gallery.created_time"
                                            :label="$t('galleries.time')"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="menuTime"
                                        v-model="gallery.created_time"
                                        full-width
                                        @click:minute="
                                            $refs.menu.save(
                                                gallery.created_time
                                            )
                                        "
                                        format="24hr"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-checkbox
                                    v-model="gallery.is_active"
                                    hide-details
                                    class="shrink mr-2 mt-0"
                                    :label="$t('galleries.is_active')"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="submit">{{
                    $t("general.save")
                }}</v-btn>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import AttachmentInput from "../components/AttachmentInput";

export default {
    name: "galleryForm",
    props: {
        form: {
            type: Object
        },
        gallery: Object,
        visible: Boolean,
        isEdited: Boolean
    },

    data() {
        return {
            menuTime: false,
            menuDate: false,
            dateFormatted: "",
            valid: true,
            is_active: false,
            rules: [value => !!value || this.$t("general.required")],
            rulesURL: [
                value =>
                    this.isValidWebUrl(value) ||
                    this.$t("general.must_be_valid_url")
            ],
            attachmentsToDelete: []
        };
    },

    watch: {
        gallery: {
            handler: function(val, oldVal) {
                this.dateFormatted = this.formatDate(this.gallery.created_date);
            },
            deep: true
        }
    },

    components: {
        AttachmentInput
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            }
        }
    },

    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        removeAttachment(id) {
            this.attachmentsToDelete.push(id);
        },
        async submit() {
            if (this.$refs.form.validate()) {
                let formData = new FormData();

                Object.entries(this.gallery).forEach(([key, value]) => {
                    if (value) {
                        formData.append(key, value);
                    } else {
                        formData.append(key, "");
                    }
                });

                if (this.gallery.image.file instanceof File) {
                    formData.append(
                        "attachments[toStore][0][file]",
                        this.gallery.image.file
                    );
                    formData.append(
                        "attachments[toStore][0][model_name]",
                        "gallery"
                    );
                }

                if (this.attachmentsToDelete.length) {
                    formData.append(
                        "attachments[toDelete]",
                        this.attachmentsToDelete
                    );
                }

                if (this.isEdited) {
                    await axios
                        .post(
                            `/api/updateGallery/${this.gallery.id}`,
                            formData,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        this.$store.state.tokenData.user
                                            .access_token
                                }
                            }
                        )
                        .then(response => {
                            this.$emit("updateGallery", response.data);
                            this.$emit(
                                "snackMessage",
                                "galleries.gallery_saved",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storeGallery`, formData, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            this.attachmentsToDelete = [];
                            this.$emit("addGallery", response.data);
                            this.$emit(
                                "snackMessage",
                                "galleries.gallery_added",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        },
        isValidWebUrl(url) {
            let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
            return regEx.test(url);
        }
    }
};
</script>
