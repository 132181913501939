var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('h1',{staticClass:"font-weight-light headline"},[_vm._v("\n                    "+_vm._s(_vm.$t("title.settings"))+"\n                ")])])],1)],1),_vm._v(" "),_c('section',{staticClass:"py-7"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","xl":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("settings.general_data")))]),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_company_name')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_company_name
                                    )}},model:{value:(
                                    _vm.settings.general_company_name.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_company_name, "value", $$v)},expression:"\n                                    settings.general_company_name.value\n                                "}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_company_street')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_company_street
                                    )}},model:{value:(
                                    _vm.settings.general_company_street.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_company_street, "value", $$v)},expression:"\n                                    settings.general_company_street.value\n                                "}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_company_city')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_company_city
                                    )}},model:{value:(
                                    _vm.settings.general_company_city.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_company_city, "value", $$v)},expression:"\n                                    settings.general_company_city.value\n                                "}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_company_ico')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_company_ico
                                    )}},model:{value:(_vm.settings.general_company_ico.value),callback:function ($$v) {_vm.$set(_vm.settings.general_company_ico, "value", $$v)},expression:"settings.general_company_ico.value"}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_company_dic')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_company_dic
                                    )}},model:{value:(_vm.settings.general_company_dic.value),callback:function ($$v) {_vm.$set(_vm.settings.general_company_dic, "value", $$v)},expression:"settings.general_company_dic.value"}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_company_dic_text')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_company_dic_text
                                    )}},model:{value:(
                                    _vm.settings.general_company_dic_text.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_company_dic_text, "value", $$v)},expression:"\n                                    settings.general_company_dic_text.value\n                                "}})],1)],1),_vm._v(" "),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("settings.other_data")))]),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_instagram')},on:{"input":function($event){return _vm.saveSetting(_vm.settings.general_instagram)}},model:{value:(_vm.settings.general_instagram.value),callback:function ($$v) {_vm.$set(_vm.settings.general_instagram, "value", $$v)},expression:"settings.general_instagram.value"}}),_vm._v(" "),_c('v-textarea',{attrs:{"label":_vm.$t('settings.general_instagram_token')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_instagram_token
                                    )}},model:{value:(
                                    _vm.settings.general_instagram_token.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_instagram_token, "value", $$v)},expression:"\n                                    settings.general_instagram_token.value\n                                "}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ap-wrapper d-inline-block",staticStyle:{"width":"193px"}},'div',attrs,false),on),[_c('v-switch',{attrs:{"color":"green","label":_vm.settings.is_on_frontend
                                                        .value
                                                        ? _vm.$t(
                                                              'settings.is_on_frontend'
                                                          )
                                                        : _vm.$t(
                                                              'settings.is_off_frontend'
                                                          ),"loading":_vm.settings.is_on_frontend
                                                        .loading},on:{"change":function($event){return _vm.saveSetting(
                                                        _vm.settings.is_on_frontend
                                                    )}},model:{value:(
                                                    _vm.settings.is_on_frontend
                                                        .value
                                                ),callback:function ($$v) {_vm.$set(_vm.settings.is_on_frontend
                                                        , "value", $$v)},expression:"\n                                                    settings.is_on_frontend\n                                                        .value\n                                                "}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(
                                            "settings.will_turn_off_pages_for_users"
                                        )))])]),_vm._v(" "),(!_vm.settings.is_on_frontend.value)?_c('v-text-field',{staticClass:"ml-3",staticStyle:{"max-width":"300px","margin-top":"11px"},attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('settings.ip_addresses')},on:{"input":function($event){return _vm.saveSetting(
                                            _vm.settings.frontend_ip_addresses
                                        )}},model:{value:(
                                        _vm.settings.frontend_ip_addresses.value
                                    ),callback:function ($$v) {_vm.$set(_vm.settings.frontend_ip_addresses, "value", $$v)},expression:"\n                                        settings.frontend_ip_addresses.value\n                                    "}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("settings.email")))]),_vm._v(" "),_c('v-card-text',[_c('v-textarea',{attrs:{"label":_vm.$t('settings.email_footer')},on:{"input":function($event){return _vm.saveSetting(_vm.settings.form_email_footer)}},model:{value:(_vm.settings.form_email_footer.value),callback:function ($$v) {_vm.$set(_vm.settings.form_email_footer, "value", $$v)},expression:"settings.form_email_footer.value"}})],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","xl":"4"}},[_c('v-card',{staticClass:"mt-5 mt-sm-0"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("settings.contact_data")))]),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_email')},on:{"input":function($event){return _vm.saveSetting(_vm.settings.general_email)}},model:{value:(_vm.settings.general_email.value),callback:function ($$v) {_vm.$set(_vm.settings.general_email, "value", $$v)},expression:"settings.general_email.value"}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_phone')},on:{"input":function($event){return _vm.saveSetting(_vm.settings.general_phone)}},model:{value:(_vm.settings.general_phone.value),callback:function ($$v) {_vm.$set(_vm.settings.general_phone, "value", $$v)},expression:"settings.general_phone.value"}})],1)],1),_vm._v(" "),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("settings.bank_data")))]),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t(
                                        'settings.general_company_bank_account'
                                    )},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_company_bank_account
                                    )}},model:{value:(
                                    _vm.settings.general_company_bank_account
                                        .value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_company_bank_account
                                        , "value", $$v)},expression:"\n                                    settings.general_company_bank_account\n                                        .value\n                                "}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_bank_acount_text')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_bank_acount_text
                                    )}},model:{value:(
                                    _vm.settings.general_bank_acount_text.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_bank_acount_text, "value", $$v)},expression:"\n                                    settings.general_bank_acount_text.value\n                                "}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_swift')},on:{"input":function($event){return _vm.saveSetting(_vm.settings.general_swift)}},model:{value:(_vm.settings.general_swift.value),callback:function ($$v) {_vm.$set(_vm.settings.general_swift, "value", $$v)},expression:"settings.general_swift.value"}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_iban')},on:{"input":function($event){return _vm.saveSetting(_vm.settings.general_iban)}},model:{value:(_vm.settings.general_iban.value),callback:function ($$v) {_vm.$set(_vm.settings.general_iban, "value", $$v)},expression:"settings.general_iban.value"}})],1)],1),_vm._v(" "),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("settings.contact_form")))]),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t(
                                        'settings.contact_form_account_number'
                                    )},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.contact_form_account_number
                                    )}},model:{value:(
                                    _vm.settings.contact_form_account_number
                                        .value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.contact_form_account_number
                                        , "value", $$v)},expression:"\n                                    settings.contact_form_account_number\n                                        .value\n                                "}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t(
                                        'settings.contact_form_account_specific'
                                    )},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.contact_form_account_specific
                                    )}},model:{value:(
                                    _vm.settings.contact_form_account_specific
                                        .value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.contact_form_account_specific
                                        , "value", $$v)},expression:"\n                                    settings.contact_form_account_specific\n                                        .value\n                                "}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.contact_form_price')},on:{"input":function($event){return _vm.saveSetting(_vm.settings.contact_form_price)}},model:{value:(_vm.settings.contact_form_price.value),callback:function ($$v) {_vm.$set(_vm.settings.contact_form_price, "value", $$v)},expression:"settings.contact_form_price.value"}})],1)],1),_vm._v(" "),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("settings.footer_contact_data")))]),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('settings.footer_contact_title_1')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.footer_contact_title_1
                                    )}},model:{value:(
                                    _vm.settings.footer_contact_title_1.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.footer_contact_title_1, "value", $$v)},expression:"\n                                    settings.footer_contact_title_1.value\n                                "}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_footer_phone_1')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_footer_phone_1
                                    )}},model:{value:(
                                    _vm.settings.general_footer_phone_1.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_footer_phone_1, "value", $$v)},expression:"\n                                    settings.general_footer_phone_1.value\n                                "}})],1),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('settings.footer_contact_title_2')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.footer_contact_title_2
                                    )}},model:{value:(
                                    _vm.settings.footer_contact_title_2.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.footer_contact_title_2, "value", $$v)},expression:"\n                                    settings.footer_contact_title_2.value\n                                "}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('settings.general_footer_phone_2')},on:{"input":function($event){return _vm.saveSetting(
                                        _vm.settings.general_footer_phone_2
                                    )}},model:{value:(
                                    _vm.settings.general_footer_phone_2.value
                                ),callback:function ($$v) {_vm.$set(_vm.settings.general_footer_phone_2, "value", $$v)},expression:"\n                                    settings.general_footer_phone_2.value\n                                "}})],1)],1)],1)],1),_vm._v(" "),_c('SnackMessage',{ref:"SnackMessage"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }