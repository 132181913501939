<template>
    <v-dialog v-model="show" max-width="1200px" :retain-focus="false">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("posts.edit_post")
                }}</span>
                <span class="headline" v-else>{{ $t("posts.add_post") }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <label>{{ $t("posts.content") }}</label>
                                <editor
                                    :editorData="post.content"
                                    @insertEditorData="insertEditorData"
                                ></editor>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    v-model="post.title"
                                    :label="$t('posts.title')"
                                    autofocus
                                    :rules="rules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-model="post.meta_description"
                                    :label="$t('posts.meta_description')"
                                    rows="3"
                                    maxlength="150"
                                    counter
                                    class="mt-5"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="submit">{{
                    $t("general.save")
                }}</v-btn>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Editor from "../components/CkeditorForm";

export default {
    name: "PostForm",
    props: {
        form: {
            type: Object
        },
        post: Object,
        visible: Boolean,
        isEdited: Boolean
    },

    components: {
        Editor
    },

    data() {
        return {
            valid: true,
            rules: [value => !!value || this.$t("general.required")]
        };
    },

    watch: {
        show(val) {}
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            }
        }
    },

    methods: {
        insertEditorData(ckeditorContent) {
            this.post.content = ckeditorContent;
        },
        async submit() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    await axios
                        .put(`/api/updatePost/${this.post.id}`, this.post, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            this.$emit("updatePost", response.data);
                            this.$emit(
                                "snackMessage",
                                "posts.post_saved",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storePost`, this.post, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            this.$emit("addPost", response.data);
                            this.$emit(
                                "snackMessage",
                                "posts.post_added",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        }
    }
};
</script>
