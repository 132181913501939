<template>
    <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("logos.edit_logo")
                }}</span>
                <span class="headline" v-else>{{ $t("logos.add_logo") }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    v-model="logo.title"
                                    :label="$t('logos.title')"
                                    autofocus
                                    :rules="rules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="logo.url"
                                    :label="$t('logos.url')"
                                    :rules="rulesURL"
                                ></v-text-field>
                                <AttachmentInput
                                    :label="$t('logos.image')"
                                    :attachment="logo.image"
                                    accept="image/*"
                                    class="mt-2 mb-4"
                                    @removeAttachment="removeAttachment"
                                />
                                <v-checkbox
                                    v-model="logo.is_active"
                                    hide-details
                                    :label="$t('logos.is_active')"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="submit">{{
                    $t("general.save")
                }}</v-btn>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AttachmentInput from "../components/AttachmentInput";
export default {
    name: "LogoForm",
    props: {
        form: {
            type: Object
        },
        logo: Object,
        visible: Boolean,
        isEdited: Boolean
    },

    data() {
        return {
            is_active: false,
            valid: true,
            rules: [value => !!value || this.$t("general.required")],
            rulesURL: [
                value =>
                    this.isValidWebUrl(value) ||
                    this.$t("general.must_be_valid_url")
            ],
            attachmentsToDelete: []
        };
    },

    watch: {
        show(val) {
            !val && this.$refs.form.reset();
        }
    },

    components: {
        AttachmentInput
    },

    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            }
        }
    },

    methods: {
        removeAttachment(id) {
            this.attachmentsToDelete.push(id);
        },
        async submit() {
            if (this.$refs.form.validate()) {
                let formData = new FormData();

                Object.entries(this.logo).forEach(([key, value]) => {
                    if (value) {
                        formData.append(key, value);
                    } else {
                        formData.append(key, "");
                    }
                });

                if (this.logo.image.file instanceof File) {
                    formData.append(
                        "attachments[toStore][0][file]",
                        this.logo.image.file
                    );
                    formData.append(
                        "attachments[toStore][0][model_name]",
                        "logo"
                    );
                }

                if (this.attachmentsToDelete.length) {
                    formData.append(
                        "attachments[toDelete]",
                        this.attachmentsToDelete
                    );
                }

                if (this.isEdited) {
                    await axios
                        .post(`/api/updateLogo/${this.logo.id}`, formData, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            this.attachmentsToDelete = [];

                            this.$emit("updateLogo", response.data);
                            this.$emit(
                                "snackMessage",
                                "logos.logo_saved",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storeLogo`, formData, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token
                            }
                        })
                        .then(response => {
                            this.attachmentsToDelete = [];

                            this.$emit("addLogo", response.data);
                            this.$emit(
                                "snackMessage",
                                "logos.logo_added",
                                "success"
                            );
                            this.$emit("close");
                        })
                        .catch(error => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        },

        isValidWebUrl(url) {
            let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
            return regEx.test(url);
        }
    }
};
</script>
