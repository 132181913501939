var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[(_vm.isEdited)?_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("galleries.edit_gallery")))]):_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("galleries.add_gallery")))])]),_vm._v(" "),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('galleries.title'),"autofocus":"","rules":_vm.rules},model:{value:(_vm.gallery.title),callback:function ($$v) {_vm.$set(_vm.gallery, "title", $$v)},expression:"gallery.title"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('galleries.description'),"rules":_vm.rules},model:{value:(_vm.gallery.description),callback:function ($$v) {_vm.$set(_vm.gallery, "description", $$v)},expression:"gallery.description"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('galleries.google_drive_url'),"rules":_vm.rulesURL},model:{value:(_vm.gallery.google_drive_url),callback:function ($$v) {_vm.$set(_vm.gallery, "google_drive_url", $$v)},expression:"gallery.google_drive_url"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('AttachmentInput',{attrs:{"label":_vm.$t('galleries.image'),"attachment":_vm.gallery.image,"accept":"image/*"},on:{"removeAttachment":_vm.removeAttachment}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('galleries.date'),"append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":"cs"},on:{"change":function($event){_vm.menuDate = false}},model:{value:(_vm.gallery.created_date),callback:function ($$v) {_vm.$set(_vm.gallery, "created_date", $$v)},expression:"gallery.created_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.gallery.created_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.gallery, "created_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.gallery, "created_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('galleries.time'),"append-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.gallery.created_time),callback:function ($$v) {_vm.$set(_vm.gallery, "created_time", $$v)},expression:"gallery.created_time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTime),callback:function ($$v) {_vm.menuTime=$$v},expression:"menuTime"}},[_vm._v(" "),(_vm.menuTime)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menu.save(
                                            _vm.gallery.created_time
                                        )}},model:{value:(_vm.gallery.created_time),callback:function ($$v) {_vm.$set(_vm.gallery, "created_time", $$v)},expression:"gallery.created_time"}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"hide-details":"","label":_vm.$t('galleries.is_active')},model:{value:(_vm.gallery.is_active),callback:function ($$v) {_vm.$set(_vm.gallery, "is_active", $$v)},expression:"gallery.is_active"}})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("general.save")))]),_vm._v(" "),_c('v-btn',{on:{"click":function($event){$event.stopPropagation();_vm.show = false}}},[_vm._v(_vm._s(_vm.$t("general.cancel")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }