<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.categories") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy
                        v-if="isCategoriesLoaded"
                        transition="fade-transition"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy
                        v-if="isCategoriesLoaded"
                        transition="fade-transition"
                    >
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="
                                dialog.categoryForm = true;
                                isCategoryEdited = false;
                            "
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!categories.length">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        :headers="headers"
                        :items="categories"
                        :search="search"
                        :items-per-page="30"
                        :footer-props="{
                            itemsPerPageOptions: [30, 60, 90, -1]
                        }"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editCategory(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:item.parent_category_id="{ item }">
                            <span>{{
                                getCategoryNameById(item.parent_category_id)
                            }}</span>
                        </template>
                    </v-data-table>
                </v-card>

                <CategoryForm
                    :category="selectedCategory"
                    :categories="categories"
                    :visible="dialog.categoryForm"
                    :isEdited="isCategoryEdited"
                    @addCategory="addCategory"
                    @updateCategory="updateCategory"
                    @close="dialog.categoryForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedCategory.title"
                    :text="'categories.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteCategory"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import CategoryForm from "../components/CategoryForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            categories: [],
            isCategoriesLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                categoryForm: false
            },
            category: {
                name: null,
                parent_category_id: null
            },
            selectedCategory: {
                name: null,
                parent_category_id: null
            },
            defaultCategory: {
                name: null,
                parent_category_id: null
            },
            isCategoryEdited: false
        };
    },

    components: {
        CategoryForm,
        ConfirmationDialog,
        SnackMessage
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false
                },
                {
                    text: this.$t("categories.name"),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t("categories.parent_category_id"),
                    align: "left",
                    sortable: false,
                    value: "parent_category_id"
                },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at"
                }
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        }
    },

    created() {
        this.getCategories();
    },

    methods: {
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },
        getCategoryNameById(id) {
            let category = this.categories.filter(obj => {
                return obj.id === id;
            });

            return category[0] ? category[0].name : "-";
        },
        async getCategories() {
            await axios
                .get("/api/categoryList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.categories = response.data;
                    this.isCategoriesLoaded = true;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        },
        addCategory(category) {
            this.categories.push(category);
        },
        editCategory(item) {
            this.selectedCategory = Object.assign({}, item);
            this.dialog.categoryForm = true;
            this.isCategoryEdited = true;
        },
        updateCategory(category) {
            let index = this.categories.findIndex(
                item => item.id == category.id
            );
            this.categories.splice(index, 1, category);
        },
        confirmDelete(item) {
            this.selectedCategory = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteCategory() {
            this.dialog.confirmation = false;
            let index = this.categories.indexOf(this.selectedCategory);

            await axios
                .delete(`/api/deleteCategory/${this.selectedCategory.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.selectedCategory = this.defaultCategory;
                    this.categories.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "categories.deleted_successfully",
                        "success"
                    );
                })
                .catch(error => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        }
    }
};
</script>
