<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.calendar") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy
                        v-if="isEventsLoaded"
                        transition="fade-transition"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy
                        v-if="isEventsLoaded"
                        transition="fade-transition"
                    >
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="
                                prepareEvent
                            "
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!events.length">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        :headers="headers"
                        :items="events"
                        :search="search"
                        :items-per-page="30"
                        :footer-props="{
                            itemsPerPageOptions: [30, 60, 90, -1]
                        }"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editEvent(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:item.date="{ item }">
                            <span>{{
                                formatDate(item.date)
                            }}</span>
                        </template>
                    </v-data-table>
                </v-card>

                <EventForm
                    :event="selectedEvent"
                    :visible="dialog.eventForm"
                    :isEdited="isEventEdited"
                    @addEvent="addEvent"
                    @updateEvent="updateEvent"
                    @close="dialog.eventForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedEvent.title"
                    :text="'events.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteEvent"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import { format, parseISO } from 'date-fns'

import EventForm from "../components/EventForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            events: [],
            isEventsLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                eventForm: false
            },
            event: {
                title: null,
                date: null,
                time: null,
            },
            selectedEvent: {
                title: null,
                date: null,
                time: null,
            },
            defaultEvent: {
                title: null,
                date: null,
                time: null,
            },
            isEventEdited: false
        };
    },

    components: {
        EventForm,
        ConfirmationDialog,
        SnackMessage
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false
                },
                {
                    text: this.$t("events.title"),
                    align: "left",
                    sortable: false,
                    value: "title"
                },
                {
                    text: this.$t("events.date"),
                    align: "left",
                    sortable: false,
                    value: "date"
                },
                {
                    text: this.$t("events.time"),
                    align: "left",
                    sortable: false,
                    value: "time"
                },
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        }
    },

    created() {
        this.getEvents();
    },

    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },
        async getEvents() {
            await axios
                .get("/api/eventList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.events = response.data;
                    this.isEventsLoaded = true;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        },
        prepareEvent() {
            this.selectedEvent = Object.assign({}, this.defaultEvent);
            
            this.dialog.eventForm = true;
            this.isEventEdited = false;
        },
        addEvent(event) {
            this.events.push(event);
        },
        editEvent(item) {
            this.selectedEvent = Object.assign({}, item);
            this.dialog.eventForm = true;
            this.isEventEdited = true;
        },
        updateEvent(event) {
            let index = this.events.findIndex(item => item.id == event.id);
            this.events.splice(index, 1, event);
        },
        confirmDelete(item) {
            this.selectedEvent = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteEvent() {
            this.dialog.confirmation = false;
            let index = this.events.indexOf(this.selectedEvent);

            await axios
                .delete(`/api/deleteEvent/${this.selectedEvent.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.selectedEvent = this.defaultEvent;
                    this.events.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "events.deleted_successfully",
                        "success"
                    );
                })
                .catch(error => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        }
    }
};
</script>
