<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.galleries") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy
                        v-if="isGalleriesLoaded"
                        transition="fade-transition"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy
                        v-if="isGalleriesLoaded"
                        transition="fade-transition"
                    >
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="prepareGallery"
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!galleries.length">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        :headers="headers"
                        :items="galleries"
                        :search="search"
                        :items-per-page="30"
                        :footer-props="{
                            itemsPerPageOptions: [30, 60, 90, -1]
                        }"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editGallery(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:item.is_active="{ item }">
                            <span
                                :class="
                                    item.is_active ? 'green--text' : 'red--text'
                                "
                                >{{
                                    item.is_active
                                        ? $t("general.yes")
                                        : $t("general.no")
                                }}</span
                            >
                        </template>
                    </v-data-table>
                </v-card>

                <GalleryForm
                    :gallery="selectedGallery"
                    :visible="dialog.galleryForm"
                    :isEdited="isGalleryEdited"
                    @addGallery="addGallery"
                    @updateGallery="updateGallery"
                    @close="dialog.galleryForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedGallery.title"
                    :text="'galleries.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteGallery"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import GalleryForm from "../components/GalleryForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            galleries: [],
            isGalleriesLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                galleryForm: false
            },
            gallery: {
                title: null,
                description: null,
                google_drive_url: null,
                is_active: false,
                created_date: null,
                created_time: null,
                image: {
                    name: ""
                }
            },
            selectedGallery: {
                title: null,
                description: null,
                google_drive_url: null,
                is_active: false,
                created_date: null,
                created_time: null,
                image: {
                    name: ""
                }
            },
            defaultGallery: {
                title: null,
                description: null,
                google_drive_url: null,
                is_active: false,
                created_date: null,
                created_time: null,
                image: {
                    name: ""
                }
            },
            isGalleryEdited: false
        };
    },

    components: {
        GalleryForm,
        ConfirmationDialog,
        SnackMessage
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false
                },
                {
                    text: this.$t("galleries.title"),
                    align: "left",
                    sortable: false,
                    value: "title"
                },
                {
                    text: this.$t("galleries.description"),
                    align: "left",
                    sortable: false,
                    value: "description"
                },
                {
                    text: this.$t("galleries.google_drive_url"),
                    align: "left",
                    sortable: false,
                    value: "google_drive_url"
                },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at"
                },
                {
                    text: this.$t("general.is_active"),
                    align: "left",
                    sortable: false,
                    value: "is_active"
                }
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        }
    },

    created() {
        this.getGalleries();
    },

    methods: {
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },
        async getGalleries() {
            await axios
                .get("/api/galleryList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.galleries = response.data;
                    this.isGalleriesLoaded = true;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        },
        prepareGallery() {
            this.selectedGallery = Object.assign({}, this.defaultGallery);

            this.dialog.galleryForm = true;
            this.isGalleryEdited = false;
        },
        addGallery(gallery) {
            this.galleries.push(gallery);
            this.galleries = _.sortBy(this.galleries, "created_at").reverse();
        },
        editGallery(item) {
            this.selectedGallery = Object.assign({}, item);
            this.dialog.galleryForm = true;
            this.isGalleryEdited = true;
        },
        updateGallery(gallery) {
            let index = this.galleries.findIndex(item => item.id == gallery.id);
            this.galleries.splice(index, 1, gallery);
            this.galleries = _.sortBy(this.galleries, "created_at").reverse();
        },
        confirmDelete(item) {
            this.selectedGallery = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteGallery() {
            this.dialog.confirmation = false;
            let index = this.galleries.indexOf(this.selectedGallery);

            await axios
                .delete(`/api/deleteGallery/${this.selectedGallery.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token
                    }
                })
                .then(response => {
                    this.selectedGallery = this.defaultGallery;
                    this.galleries.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "galleries.deleted_successfully",
                        "success"
                    );
                })
                .catch(error => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        }
    }
};
</script>
